<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR BECA -->
         <v-dialog v-model="dialogBeca" persistent max-width="600px">
            <v-form ref="formBecas" v-model="validBeca" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="carnet"
                                 v-mask="'####-AA-###'"
                                 :rules="rulesCarnet"
                                 color="primary"
                                 label="Carnet"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="ciclo"
                                 :rules="rulesCiclo"
                                 color="primary"
                                 label="Ciclo"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="talonario"
                                 :rules="rulesTalonario"
                                 color="primary"
                                 label="talonario"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs6>
                              <v-select
                                 v-model="selectInstitucion"
                                 :items="cmbInstitucion"
                                 :rules="rulesInstitucion"
                                 color="primary"
                                 item-text="nombre"
                                 item-value="institucionPatrocinadoraId"
                                 label="Institución"
                                 no-data-text="Sin resultados"
                                 placeholder="Seleccionar..."
                              ></v-select>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validBeca"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <v-dialog v-model="dialogEnviandoComp" persistent width="300">
            <v-card color="primary" dark class="pt-5 pb-2">
               <v-card-text style="color: white">
                  Enviando becarios externos
                  <v-progress-linear
                     indeterminate
                     color="white"
                     class="mb-0"
                  ></v-progress-linear>
               </v-card-text>
            </v-card>
         </v-dialog>

         <!-- DIALOG ELIMINAR  -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover beca</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover la beca?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ carnet }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ ciclo }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerBecas"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ADJUNTAR -->
         <v-dialog v-model="dialogAdjunto" persistent max-width="600px">
            <v-form ref="formAdjunto" v-model="validAdjunto" lazy-validation>
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Adjuntar listado</span
                              >
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12>
                              <v-file-input
                                 v-model="archivosAdjuntos"
                                 color="primary"
                                 :rules="adjuntosRules"
                                 label="Archivo adjunto"
                                 placeholder="Selecciona tu archivo"
                                 prepend-icon="fa-paperclip"
                                 accept=".csv"
                                 outlined
                                 multiple
                                 counter
                                 :show-size="1000"
                              >
                                 <template #selection="{ text }">
                                    <v-chip color="primary" dark label small>
                                       {{ text }}
                                    </v-chip>
                                 </template>
                              </v-file-input>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validAdjunto"
                        @click.native="saveAdjunto"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Becas externas</v-toolbar-title
               >
               <div class="mt-6 ml-3" style="width: 150px">
                  <v-autocomplete
                     v-model="selectCicloBecas"
                     :items="cmbCiclosBecas"
                     color="primary"
                     item-text="nombre"
                     item-value="nombre"
                     label="Ciclo"
                     no-data-text="Sin resultados"
                     placeholder="Seleccionar..."
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        v-on="on"
                        @click="nuevaBeca()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px"> Nueva Beca </span>
               </v-tooltip>
               <v-tooltip bottom color="primary">
                  <template #activator="{ on, attrs }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="dialogAdjunto = true"
                     >
                        <v-icon>fas fa-book</v-icon>
                     </v-btn>
                  </template>
                  <span>Adjuntar CSV</span>
               </v-tooltip>
            </v-toolbar>

            <!-- DATATABLE becas -->
            <v-data-table
               :headers="headers"
               :items="filteredBecas"
               :loading="loadingBecas"
               loading-text="Listando becas, por favor espere..."
               no-data-text="No se encontraron becas para el ciclo seleccionado."
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #footer="{}">
                  <tr>
                     <td :colspan="headers.length">
                        <v-layout align-center justify-start fill-height>
                           <div class="mx-4">
                              <span class="text-body-1 font-weight-medium">
                                 Buscar por
                              </span>
                           </div>
                           <div style="margin-top: 5px">
                              <v-radio-group v-model="radiosBusqueda" row>
                                 <v-radio
                                    label="Nombre"
                                    color="primary"
                                    value="r-nombre"
                                 ></v-radio>
                                 <v-radio
                                    label="Carnet"
                                    color="primary"
                                    value="r-carnet"
                                 ></v-radio>
                                 <v-radio
                                    label="Carrera"
                                    color="primary"
                                    value="r-carrera"
                                 ></v-radio>
                                 <v-radio
                                    label="Patrocinador"
                                    color="primary"
                                    value="r-patrocinador"
                                 ></v-radio>
                                 <v-radio
                                    label="Talonario"
                                    color="primary"
                                    value="r-talonario"
                                 ></v-radio>
                              </v-radio-group>
                           </div>
                        </v-layout>
                     </td>
                  </tr>
               </template>
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Eliminar </span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="becas.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Becas',
   directives: {
      mask
   },
   data: () => ({
      // Variables
      validAdjunto: true,
      radiosBusqueda: 'r-nombre',
      dialogBeca: false,
      dialogEnviandoComp: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Carnet', value: 'carnet', class: 'titulo' },
         { text: 'Nombre', value: 'nombre', class: 'titulo', width: '250' },
         { text: 'Carrera', value: 'carrera', class: 'titulo', width: '250' },
         { text: 'Ciclo', value: 'ciclo', class: 'titulo' },
         { text: 'Fecha Adjunto', value: 'fecha', class: 'titulo' },
         { text: 'Talonario', value: 'numeroTalonario', class: 'titulo' },
         {
            text: 'Institución',
            value: 'institucionPatrocinadora',
            class: 'titulo',
            width: '200'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      becas: [],
      swGeneraVenta: false,
      dialogBecaComp: false,
      cmbCiclosBecas: [],
      selectCicloBecas: '',
      loadingBecas: false,
      dialogAdjunto: false,
      validBeca: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      selectInstitucion: '',
      cmbInstitucion: [],
      archivosAdjuntos: [],

      // Propiedades bodega
      becarioExternoId: '',
      carnet: '',
      ciclo: '',
      periodosLectivos: [],
      selectTipo: '',
      cmbTipoBeca: [],
      talonario: '',
      apellidos: '',
      email: '',
      direccion: '',
      dui: '',
      nit: '',

      // Reglas de validacion de formularios
      rulesCarnet: [(v) => !!v || 'Ingrese una carnet'],
      rulesCiclo: [(v) => !!v || 'Ingrese una ciclo'],
      rulesInstitucion: [(v) => !!v || 'Ingrese una Institución'],
      rulesTalonario: [(v) => !!v || 'Ingrese una talonario'],
      adjuntosRules: [
         (files) => files.length > 0 || 'Seleccione un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'text/csv') ||
            'Solo se permiten archivos csv',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ]
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nueva beca externa'
            : 'Editar beca externa'
      },
      filteredBecas() {
         switch (this.radiosBusqueda) {
            case 'r-nombres':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.nombre)
                  )
               })
               break
            case 'r-carnet':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.carnet)
                  )
               })
               break
            case 'r-carrera':
               return this.becas.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.carrera)
                  )
               })
               break
            case 'r-patrocinador':
               return this.becas.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(
                        i.institucionPatrocinadora
                     )
                  )
               })
               break
            case 'r-talonario':
               return this.becas.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.numeroTalonario)
                  )
               })
               break
            default:
               return this.becas
         }
      }
   },
   watch: {
      selectCicloBecas() {
         this.listarBecas()
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(11, 'ver')) {
         this.listarComboBoxes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarBecas() {
         this.becas = []
         this.loadingBecas = true
         // Obteniendo becas
         axios
            .get('api/BecarioExterno?Ciclo=' + this.selectCicloBecas)
            .then((response) => {
               this.becas = response.data
               this.loadingBecas = false
            })
            .catch((error) => {
               console.log(error)
               this.loadingBecas = false
            })
      },
      // Si no cargaron bien las becas en el datatable se puede resetear
      reset() {
         this.listarBecas()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/BecarioExterno/' + item.becarioExternoId)
            .then((response) => {
               this.result = response.data
               this.becarioExternoId = this.result.becarioExternoId
               this.carnet = this.result.carnet
               this.ciclo = this.result.ciclo
               this.selectInstitucion = this.result.institucionPatrocinadoraId
               this.talonario = this.result.numeroTalonario
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogBeca = true
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.carnet = item.carnet
         this.ciclo = item.ciclo
         this.becarioExternoId = item.becarioExternoId

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },

      // Cerrar ventana de dialogo
      close() {
         this.dialogBeca = false
         this.dialogAdjunto = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formBecas.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put('api/BecarioExterno/' + this.becarioExternoId, {
                     carnet: this.carnet,
                     numeroTalonario: this.talonario,
                     institucionPatrocinadoraId: this.selectInstitucion,
                     ciclo: this.ciclo
                  })
                  .then((error) => {
                     this.listarBecas()
                     this.close()
                     this.snackText = error.response.data.detail
                     this.snackColor = 'snackError'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/BecarioExterno', {
                     carnet: this.carnet,
                     numeroTalonario: this.talonario,
                     institucionPatrocinadoraId: this.selectInstitucion,
                     ciclo: this.ciclo
                  })
                  .then(() => {
                     this.listarBecas()
                     this.close()
                     this.snackText = 'Beca agregada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formBecas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         try {
            this.$refs.formAdjunto.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.carnet = ''
         this.ciclo = ''
         this.talonario = ''
         this.selectInstitucion = ''
         this.archivosAdjuntos = []
      },
      nuevaBeca() {
         this.dialogBeca = true
      },
      removerBecas() {
         // Enviando metodo delete
         axios
            .delete('api/BecarioExterno?id=' + this.becarioExternoId)
            .then(() => {
               this.listarBecas()
               this.close()
               this.snackText = 'Beca removida exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      listarComboBoxes() {
         // Obteniendo sub categorias
         axios
            .get('api/InstitucionPatrocinadora')
            .then((response) => {
               this.cmbInstitucion = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/Ciclos')
            .then((response) => {
               this.cmbCiclosBecas = response.data
               this.selectCicloBecas = response.data[0].nombre
            })
            .catch((error) => {
               console.log(error)
            })
      },
      showDetalles(item) {
         this.carnet = item.carnet
         this.periodosLectivos = []
         this.periodosLectivos.push(item.ciclo)
         this.dialogBecaComp = true
      },
      saveAdjunto() {
         if (this.$refs.formAdjunto.validate()) {
            // Codigo para guardar
            this.dialogEnviandoComp = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('files', file, file.name)
            }
            axios
               .post('api/BecarioExterno/migrar', formData)
               .then(() => {
                  this.close()
                  this.snackText = 'Becarios agregados exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.dialogEnviandoComp = false
                  this.listarBecas()
               })
               .catch((error) => {
                  console.log(error)
                  this.snackText = error.response.data.detail
                  this.snackColor = 'snackError'
                  this.snackbar = true
                  this.close()
                  this.dialogEnviandoComp = false
               })
         }
      }
   }
}
</script>
